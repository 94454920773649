import React from 'react'
import { Form, Input, Typography } from 'antd'
import cn from 'classnames'
import { useTranslation } from 'lib/i18n'

import Heading from 'components/Heading/Heading'

import CtaButton from '../CtaButton/CtaButton'

import styles from './EmailSignupForm.module.css'

const { Text } = Typography

const percent = 10

function EmailSignupForm({
  loading,
  done,
  onFinish,
  heading,
  text,
  couponCode,
  isPopup,
  darkTextColor = false,
}) {
  const { t } = useTranslation()
  const popupCypressProp = {}

  if (isPopup) {
    popupCypressProp['data-cy'] = 'newsletterPopup'
  }

  return (
    <Form onFinish={onFinish} layout="vertical" {...popupCypressProp}>
      {heading && (
        <Heading level="h3" color="light">
          <h3>{heading}</h3>
        </Heading>
      )}
      {done ? (
        <div className={styles.done}>
          <div
            className={cn(styles.text, {
              [styles.darkTextColor]: darkTextColor,
            })}
          >
            {t(
              'EmailSignupForm.thanksText',
              'Thanks for subscribing. You will hear from us soon!',
            )}
          </div>
          {couponCode && (
            <div className={styles.couponCode} data-cy="newsletterCoupon">
              {t(
                'EmailSignupForm.couponCodeText',
                'Use this code to get {{percent}}% on your next order:',
                {
                  percent,
                },
              )}
              <div>
                <Text copyable>{couponCode}</Text>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div
          className={cn(styles.text, {
            [styles.darkTextColor]: darkTextColor,
          })}
        >
          {text}
        </div>
      )}

      {!done && (
        <div className={styles.fields}>
          <Form.Item
            name="email"
            id="subscribeemail"
            rules={[
              {
                required: true,
                type: 'email',
                message: t(
                  'EmailSignupForm.emailInvalid',
                  'Needs to be a valid email address',
                ),
              },
            ]}
          >
            <Input
              type="email"
              size="large"
              addonAfter={
                <CtaButton
                  htmlType="submit"
                  block
                  disabled={loading}
                  size="large"
                  type="primary"
                >
                  {t('EmailSignupForm.cta', 'Submit')}
                </CtaButton>
              }
              disabled={loading}
              placeholder={t('EmailSignupForm.emailPlaceholder', 'Your email')}
            />
          </Form.Item>
        </div>
      )}
    </Form>
  )
}

export default EmailSignupForm
