import { gql, useMutation } from '@apollo/client'
import { currentMarketIdVar } from 'graphql/reactive'

const mutation = gql`
  mutation NewsletterSubscribe(
    $marketId: ID!
    $input: NewsletterSubscribeInput!
  ) {
    market(id: $marketId) {
      newsletterSubscribe(input: $input) {
        errors {
          identifier
          message
          field
        }
        result
        couponCode
      }
    }
  }
`

export default function useNewsletterSubscribe({ onCompleted = () => {} }) {
  const marketId = currentMarketIdVar()

  const [mutate, { loading }] = useMutation(mutation, {
    onCompleted,
  })

  const newsletterSubscribe = async (input) => {
    if (!input) throw new Error('input is required')

    const { data } = await mutate({
      variables: {
        marketId,
        input,
      },
    })
    return data?.market?.newsletterSubscribe
  }

  return { newsletterSubscribe, loading }
}
