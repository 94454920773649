import React, { useState } from 'react'
import { useTranslation } from 'lib/i18n'

import EmailSignupForm from 'components/EmailSignupForm/EmailSignupForm'
import useCustomerData from 'hooks/useCustomerData'
import useNewsletterSubscribe from 'hooks/useNewsletterSubscribe'

import trackNewsletterSubscription from '../../lib/tracking/avo/trackNewsletterSubscription'

const percent = 10

function NewsletterSignup() {
  const { t } = useTranslation()
  const [done, setDone] = useState(false)

  const customerData = useCustomerData()
  const { newsletterSubscribe, loading } = useNewsletterSubscribe({
    onCompleted: () => {
      setDone(true)
    },
  })

  const [couponCode, setCouponCode] = useState('')
  return (
    <EmailSignupForm
      subscribe={newsletterSubscribe}
      loading={loading}
      done={done}
      onFinish={async (input) => {
        const subscribeResult = await newsletterSubscribe(input)
        setCouponCode(subscribeResult.couponCode)
        if (customerData) {
          customerData.logEvent('newsletter-subscribe', { email: input.email })
          customerData.modify({
            email: input.email,
            subscriptionType: 'opted_in',
          })
        }
        trackNewsletterSubscription()
      }}
      couponCode={couponCode}
      heading={t('EmailSignupForm.title', 'Newsletter')}
      text={t(
        'EmailSignupForm.infoText',
        'Get real time updates and offers from memmo. Subscribe to get a {{percent}}% discount on your next order.',
        {
          percent,
        },
      )}
    />
  )
}

export default NewsletterSignup
