import { pushToDataLayer } from 'lib/tracking'
import getMarketTrackingProperties from 'lib/tracking/avo/utils/getMarketTrackingProperties'

const trackNewsletterSubscription = async (): Promise<void> => {
  const { newsletterSubscribed } = await import('./sdk')
  newsletterSubscribed({
    ...getMarketTrackingProperties(),
  })

  pushToDataLayer({
    event: 'newsletterSubscription',
  })
}

export default trackNewsletterSubscription
